<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import axios from 'axios';
import Swal from "sweetalert2";
import LoadingVue from "./components/Loading.vue";
export default {
    page: {
        title: "Showmax",
        meta: [{ name: "description", content: appConfig.description }],
    },
    data() {
        return {
            title: "Showmax",
            link: process.env.VUE_APP_URL,
            item: null,
            load : false,
            subload: false,
            amount: null,
            selectedplan: null,
            plans: [],
        };
    },
    name: "Widgets",
    components: {
        Layout,
        PageHeader,
        LoadingVue
    },
    watch: {
        selectedplan: function(val){



            this.amount = val.price;
        }
    },
    created() {
        this.getitem();
    },

    methods: {
              async processtransaction() {
       this.subload = true;

          await axios.post(process.env.VUE_APP_MAIN_URL + "/process/showmax", { plan: this.selectedplan.datacode, planname: this.selectedplan.name,  amount: this.selectedplan.price }).catch((error) => {

              this.subload = false;

            if (error.response.status == 420) {
               Swal.fire("Error!", error.response.data.data, "error");
            }else {
                  Swal.fire("Error!", "Please make sure you have active internet or fields are correct", "error");
            }

               
                throw error;

            }).then((response) => {
                this.subload = false;
                 this.$router.push({name: "transaction", params: { hash: response.data.data.data.hash}});

            })

      },
        
        async getitem() {
            this.load = true;
            await axios.get(process.env.VUE_APP_MAIN_URL + "/get/showmax").catch((error) => {
                 this.load = false;
if (error.response.status == 420) {
       Swal.fire("Error!", error.response.data.data, "error");
} else {
     Swal.fire("Error!", "Please Check Your Internet Connection", "error");  
}

             
                throw error;

            }).then((response) => {

                if (response.data.status == true) {

                    this.item = response.data.data.data;
                      this.plans = response.data.data.plans;


                }
    this.load = false;
            })
        },
    },
};
</script>
<style scoped>
.active {
    border: 2px solid green;
}
</style>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
      <LoadingVue v-if="load" />
    
        <div class="row g-2" v-else>
            <div class="col-md-8 mx-auto">
                  <Notification />
    <div class="row">
    
                <div class="col-md-4">
                    <div>
    
                        <div class="row">
                            <div class="col-12" v-if="item">
                                <div class="card active">
    
                                      <div class="card-body p-1 ">
                                            <div class="form-check custom-option custom-option-basic checked">
                                                <label class="form-check-label custom-option-content" :for="item.code">
                      
                        <img :src="link+item.image" alt="" class="rounded-circle avatar-sm  me-2">
                        <span class="custom-option-body">
                          <strong class="fs-5">{{item.name}}</strong>
                        </span>
                      </label>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        
                        </div>
    
    
                        <!--end row-->
    
                        <!--end offcanvas-->
                    </div>
                </div>

                
                <div class="col-md-8">
     <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Showmax Voucher</h4>
          
          </div>
          <!-- end card header -->
          <div class="card-body">
           
            <div class="live-preview">
              <form action="javascript:void(0);">
                 <div class="mb-3" v-if="plans">
                  <label for="employeeName" class="form-label"
                    >Select Plan</label
                  >
                   <select class="form-select mb-3" aria-label="Default select example" v-model="selectedplan">

    <option :value="item" v-for="item in plans"  :key="item.id">{{item.name}} </option>
    
</select>
                </div>
                <div class="mb-3" v-if="amount">
                  <label for="employeeUrl" class="form-label"
                    >Plan Amount</label
                  >
                  <input
                    type="url"
                    class="form-control"
                    readonly
                    v-model="amount"
                    id="employeeUrl"
                    placeholder="Enter emploree url"
                  />
                </div>
               
                <div class="text-end">
                  <button type="submit" class="btn btn-primary" :disabled="subload" @click="processtransaction">
                   <div class="spinner-border spinner-border-sm" role="status" v-if="subload" > 
  <span class="sr-only">Loading...</span>
</div>
                Continue
                  </button>
                </div>
              </form>
            </div>
          
          </div>
        </div>
      </div>
   
    </div>
                </div>
                </div>
            </div>
            <!-- end col -->
        </div>
        <!--end row-->
    
    
    </Layout>
</template>
